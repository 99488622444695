@font-face {
  font-family: 'OpenDyslexic';
  src: url('fonts/OpenDyslexic-Regular.woff2') format('woff2'),
    url('fonts/OpenDyslexic-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('fonts/OpenDyslexic-Italic.woff2') format('woff2'),
    url('fonts/OpenDyslexic-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('fonts/OpenDyslexic-Bold.woff2') format('woff2'),
    url('fonts/OpenDyslexic-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('fonts/OpenDyslexic-Bold-Italic.woff2') format('woff2'),
    url('fonts/OpenDyslexic-Bold-Italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}